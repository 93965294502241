<div class="col-sm-12 px-0 mt-5 d-flex flex-row">
  <div class="col-sm-4 red-background d-flex align-items-center justify-content-end">
    <img class="img-fluid"
         [src]="dateTime.getHours() >= evening || dateTime.getHours() <= morning ? footerWhiteLogo : footerWhiteLogo"
         style="max-width: 3.5rem">
  </div>
  <div class="col-sm-3 d-flex align-items-center"
       [ngStyle]="{ backgroundColor: sectionBGColor}">
    <img class="img-fluid" src="assets/img/RCA.png" style="max-width: 5rem">
  </div>
  <div class="col-sm-3 d-flex align-items-end justify-content-end justify-content-around flex-column"
       [ngStyle]="{ backgroundColor: sectionBGColor}">
    <div>
      <p class="my-0 text-right d-none d-sm-block" style="font-family: 'Circe Bold'; font-size: small" [ngStyle]="{ color: textColor }">For any
        questions</p>
      <p class="my-0 d-none d-sm-block" style="font-family: 'Circe Bold'; font-size: small; color: #0C23FE;">
        contact@startbusiness-russia.com</p>
    </div>

    <div>
      <p class="my-0 d-none d-sm-block" style="font-family: 'Circe Bold'; font-size: small" [ngStyle]="{ color: textColor }">&copy; "Russian
        Consulting Advisory", 2018-2021</p>
    </div>
  </div>
</div>
