<section [ngStyle]="{ backgroundColor: sectionBGColor}">

  <app-navbar></app-navbar>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <!-- Col for alignment -->
      <div class="col-sm-9 mb-5">

        <!-- "Accounting and Tax Filling" heading col -->
        <div class="col-sm-12 py-5">
          <h1 [ngStyle]="{ color: textColor}"
              style="font-family: 'Circe Bold'; font-size: xxx-large" translate>Accounting and Tax Filling</h1>
        </div>
        <!-- end of "Accounting and Tax Filling" heading col -->

        <!-- Col for center alignment -->
        <div class="col-sm-12 d-flex flex-row justify-content-around">

          <!-- Col for arrow and rotated heading -->
          <div class="d-none d-sm-block">
            <div class="col  d-none d-sm-block">
              <img class="img-fluid mt-1 d-none d-sm-block" src="assets/img/arrow.png" style="height: 85rem">
            </div>
          </div>
          <!-- end of Col for arrow and rotated heading -->


          <!-- Col for content at right -->
          <div class="col-sm-9 col-12">
            <!---------->
            <div class="col-sm-12 m-2 mb-4 pt-5 ml-0"
                 [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">

              <div class="row d-flex flex-row">
                <div class="col-1 pl-0">
                  <div class="blue-stripe-h5"></div>
                </div>
                <div class="col-10">
                  <h5 style="font-family: 'Circe Bold'; font-size: xx-large;" translate>Advantages of accounting <br translate>
                    and
                    book-keeping
                    services</h5>
                </div>
              </div>

              <div class="row d-flex flex-row py-5">
                <div class="col-sm-1"></div>
                <div class="col-sm-11">
                  <ul class="pl-0 ml-3" style="font-family: 'Circe'; font-size: large">
                    <li class="py-2" translate>Getting to know the company’s financial position regularly</li>
                    <li class="py-2" translate>Early revealing of the company’s crises and assisting on seeking solution</li>
                    <li class="py-2" translate>Maintaining a clear and systematic system for the company</li>
                    <li class="py-2" translate>Prevention of misappropriation of the company’s assets</li>
                  </ul>
                </div>
              </div>

              <div class="row p-0">
                <div class="col-sm-12 p-0 d-flex justify-content-end align-items-end">
                  <div class="red-line-bottom">

                  </div>
                </div>
              </div>

            </div>
            <!---------->
            <div class="col-sm-12 m-2 my-4 pt-5 ml-0"
                 [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">

              <div class="row d-flex flex-row">
                <div class="col-1 pl-0">
                  <div class="blue-stripe-h5"></div>
                </div>
                <div class="col-10 d-flex align-items-center">
                  <h5 style="font-family: 'Circe Bold'; font-size: xx-large" translate> Annual Maintenance </h5>
                </div>
              </div>

              <div class="row d-flex flex-row py-5">
                <div class="col-sm-1 pl-0"></div>
                <div class="col-sm-11 row">
                  <div class="col-sm-2 mx-1 row flex-column justify-content-between">
                    <p style="font-family: 'Circe ExtraBold'; font-size: large" translate>Accounting IFRS</p>

                    <p style="font-family: 'Circe'; font-size: large" translate>From 100 EUR <br translate> quarter</p>
                  </div>
                  <div class="col-sm-3 mx-1 row flex-column justify-content-between">
                    <p style="font-family: 'Circe ExtraBold'; font-size: large" translate>Accounting <br translate> Russian standards</p>

                    <p style="font-family: 'Circe'; font-size: large" translate>From 100 EUR <br translate> quarter</p>
                  </div>
                  <div class="col-sm-3 mx-1 row flex-column justify-content-between">
                    <p style="font-family: 'Circe ExtraBold'; font-size: large" translate>Accounting <br translate> Russian standards <br translate> +
                      IFRS</p>

                    <p style="font-family: 'Circe'; font-size: large" translate>From 150 EUR <br translate> quarter</p>
                  </div>
                  <div class="col-sm-3 mx-1 row flex-column justify-content-between">
                    <p style="font-family: 'Circe ExtraBold'; font-size: large" translate>Tax filling</p>

                    <p style="font-family: 'Circe'; font-size: large" translat>From 50 EUR <br translate> paper</p>
                  </div>
                </div>
              </div>

              <div class="row p-0">
                <div class="col-sm-12 p-0 d-flex justify-content-end align-items-end">
                  <div class="red-line-bottom">

                  </div>
                </div>
              </div>

            </div>
            <!---------->
            <div class="col-sm-12 m-2 mb-4 pt-5 ml-0"
                 [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">

              <div class="row d-flex flex-row">
                <div class="col-1 pl-0">
                  <div class="blue-stripe-h5"></div>
                </div>
                <div class="col-10 d-flex align-items-center">
                  <h5 style="font-family: 'Circe Bold'; font-size: xx-large; color: #0C23FE" translate> What we do </h5>
                </div>
              </div>

              <div class="row d-flex flex-row py-5">
                <div class="col-sm-1"></div>
                <div class="col-sm-11">
                  <ul class="pl-0 ml-3" style="font-family: 'Circe'; font-size: large">
                    <li class="py-2" translate>Computerized accounting records for permanent storage</li>
                    <li class="py-2" translate>Preparation of financial statement including Balance Sheet and Income Statement
                    </li>
                  </ul>

                  <p style="font-family: 'Circe ExtraBold'; color: #0C23FE" translate>Prepare</p>
                  <div class="row d-flex flex-row">
                    <div class="col-sm-4">
                      <ul class="pl-0 ml-3" style="font-family: 'Circe'; font-size: large">
                        <li class="py-2" translate>General Ledger</li>
                        <li class="py-2" translate>Cash account</li>
                      </ul>
                    </div>
                    <div class="col-sm-4">
                      <ul class="pl-0 ml-3" style="font-family: 'Circe'; font-size: large">
                        <li class="py-2" translate>Bank account</li>
                        <li class="py-2" translate>Account Receivable</li>
                      </ul>
                    </div>
                    <div class="col-sm-4">
                      <ul class="pl-0 ml-3" style="font-family: 'Circe'; font-size: large">
                        <li class="py-2" translate>Accounts Payable</li>
                      </ul>
                    </div>
                  </div>


                </div>
              </div>

              <div class="row p-0">
                <div class="col-sm-12 p-0 d-flex justify-content-end align-items-end">
                  <div class="blue-line-bottom">

                  </div>
                </div>
              </div>

            </div>
            <!---------->
          </div>
          <!-- end of Col for content at right -->

        </div>
        <!-- Col for center alignment -->

      </div>
      <!-- end of Col for alignment -->
    </div>
  </div>

  <app-footer></app-footer>

</section>
