import { Component, OnInit } from '@angular/core';
import {Time} from '../time';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent extends Time {
}
