<section [ngStyle]="{ backgroundColor: sectionBGColor}">

  <app-navbar></app-navbar>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <!-- Col for alignment -->
      <div class="col-sm-9">

        <!-- "Company Formation" heading col -->
        <div class="col-sm-12 py-5">
          <h1 style="font-family: 'Circe Bold'; font-size: xxx-large"
              [ngStyle]="{ color: textColor}" translate>Company Formation</h1>
        </div>
        <!-- end of "Company Formation" heading col -->

        <!-- Col for content -->
        <div class="col-sm-12 d-flex flex-sm-row flex-column">

          <!-- "Business Registration" col -->
          <div class="col-sm-4 m-2 pt-4 pl-0 d-flex flex-column justify-content-between"
               [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">
            <div>

              <div class="row">
                <!-- Stripe Col -->
                <div class="col-1">
                  <div class="red-stripe-h5"></div>
                </div>
                <!-- end of Stripe Col -->
                <div class="col-10 d-flex align-items-center">
                  <h5 style="font-family: 'Circe Bold'; font-size: xx-large" translate> Business Registration </h5>
                </div>

              </div>

              <!-------------->

              <div class="row pt-4 pt-3">
                <div class="col-1">

                </div>
                <div class="col-10">
                  <p class="mb-0" style="font-family: 'Circe ExtraBold'" translate>LLC - 1500 EUR</p>
                  <p class="text-muted mt-0" style="font-family: 'Circe Light'; font-size: small" translate>(other type
                    of ownership on request)</p>
                </div>
              </div>

              <!-------------->
            </div>

            <div class="row py-3">
              <div class="col-1">

              </div>
              <div class="col-10">
                <p style="color: #0C23FE; font-family: 'Circe Bold'" translate>You get:</p>
                <ul class="pl-0 ml-3" style="font-size: small; font-family: 'Circe'">

                  <li class="py-1" translate>Package of documents of state registration</li>

                  <li class="py-1" translate>An agreement with the Pension Fund</li>

                  <li class="py-1" translate>Bank account - RUB, EUR, USD</li>

                  <li class="py-1" translate>Translation of documents</li>

                  <li class="py-1" translate>Stamp</li>

                </ul>
              </div>
            </div>

            <div class="row p-0">
              <div class="col-sm-12 p-0 d-flex justify-content-end align-items-end">
                <div class="blue-line-bottom">

                </div>
              </div>
            </div>

          </div>
          <!-- end of "Business Registration" col -->

          <!-- "Business Liquidation" col -->
          <div class="col-sm-4 m-2 pt-4 pl-0 d-flex flex-column justify-content-between"
               [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">
            <div>
              <div class="row">

                <div class="col-1">
                  <div class="red-stripe-h5"></div>
                </div>

                <div class="col-10 d-flex align-items-center">
                  <h5 style="font-family: 'Circe Bold'; font-size: xx-large" translate> Business Liquidation </h5>
                </div>

              </div>

              <!-------------->

              <div class="row pt-4 pt-3">
                <div class="col-1">

                </div>
                <div class="col-10">
                  <p class="mb-0" style="font-family: 'Circe ExtraBold'" translate>From 1000 EUR</p>
                </div>
              </div>

              <!-------------->
            </div>

            <div class="row py-3">
              <div class="col-1">

              </div>
              <div class="col-10">
                <p style="font-family: 'Circe'; font-size: small" translate>Liquidation in Russia is a laborious and
                  time-consuming process, an accurate assessment requires a detailed review of the situation.</p>
              </div>
            </div>

            <div class="row p-0">
              <div class="col-sm-12 p-0 d-flex justify-content-end align-items-end">
                <div class="blue-line-bottom">

                </div>
              </div>
            </div>

          </div>
          <!-- end of "Business Liquidation" col -->

          <!-- "Annual Maintenance" col -->
          <div class="col-sm-4 m-2 pt-4 pl-0 d-flex flex-column justify-content-between"
               [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">
            <div>
              <div class="row">

                <div class="col-1">
                  <div class="red-stripe-h5"></div>
                </div>

                <div class="col-10 d-flex align-items-center">
                  <h5 style="font-family: 'Circe Bold'; font-size: xx-large" translate> Annual Maintenance </h5>
                </div>

              </div>

              <!-------------->

              <div class="row pt-4 pt-3">
                <div class="col-1">

                </div>
                <div class="col-10">
                  <p class="mb-0" style="font-family: 'Circe'; font-size: small" translate>On the territory of Russia as a whole,
                    the
                    anti-money laundering systems and controls system operates. Also, the state strictly refers to the
                    correct design of addresses and top officials of companies.</p>
                </div>
              </div>

              <!-------------->
            </div>


            <div class="row py-3">
              <div class="col-1">

              </div>
              <div class="col-5">
                <p class="my-0" style="font-family: 'Circe Bold'; font-size: small" translate>Office Rent</p>

                <p style="font-family: 'Circe'; font-size: small" translate>from 1,000 EUR per year</p>
              </div>
              <div class="col-5">
                <p class="my-0" style="font-family: 'Circe Bold'; font-size: small" translate>Director Services</p>

                <p style="font-family: 'Circe'; font-size: small" translate>from 300 EUR per month</p>
              </div>
            </div>

            <div class="row p-0">
              <div class="col-sm-12 p-0 d-flex justify-content-end align-items-end">
                <div class="blue-line-bottom">

                </div>
              </div>
            </div>

          </div>
          <!-- end of "Annual Maintenance" col -->

        </div>
        <!-- end of Col for content -->

      </div>
      <!-- end of Col for alignment -->
    </div>
  </div>

  <app-footer></app-footer>

</section>
