import { Component, OnInit } from '@angular/core';
import {Time} from '../time';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends Time{

  navbarBlackLogo = 'assets/img/logo-nav-black.png';
  navbarWhiteLogo = 'assets/img/logo-nav-white.png';

}
