import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {CompanyFormationComponent} from './company-formation/company-formation.component';
import {AccountingComponent} from './accounting/accounting.component';
import {PayrollComponent} from './payroll/payroll.component';
import {BusinessAdviceComponent} from './business-advice/business-advice.component';
import {ContactComponent} from './contact/contact.component';

const routes: Routes = [
  {path: '', component: LandingPageComponent, data: {animation: 'LandingPageComponent'}},
  {path: 'company-formation', component: CompanyFormationComponent, data: {animation: 'CompanyFormationComponent'}},
  {path: 'accounting', component: AccountingComponent, data: {animation: 'AccountingComponent'}},
  {path: 'payroll', component: PayrollComponent, data: {animation: 'PayrollComponent'}},
  {path: 'business-advice', component: BusinessAdviceComponent, data: {animation: 'BusinessAdviceComponent'}},
  {path: 'contact', component: ContactComponent, data: {animation: 'ContactComponent'}},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
