import {Component, OnInit} from '@angular/core';
import {Time} from '../time';

@Component({
  selector: 'app-business-advice',
  templateUrl: './business-advice.component.html',
  styleUrls: ['./business-advice.component.scss']
})
export class BusinessAdviceComponent extends Time {
}
