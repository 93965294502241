<section [ngStyle]="{backgroundColor: sectionBGColor}">

  <app-navbar></app-navbar>

  <div class="container-fluid">

    <div class="row justify-content-center">

      <!-- Col for center alignment -->
      <div class="col-sm-9">
        <!-- "Company Formation" heading col -->
        <div class="col-sm-12 py-5" [ngStyle]="{ color: textColor }">
          <h1 style="font-family: 'Circe Bold'; font-size: xxx-large" translate>Payroll</h1>
        </div>
        <!-- end of "Company Formation" heading col -->

        <!-- "In Russia" col -->
        <div class="col-sm-10 my-5">
          <div class="row d-flex flex-row">
            <div class="col-1 d-none d-sm-block">
              <div class="white-stripe"></div>
              <div class="red-stripe"></div>
              <div class="blue-stripe"></div>
            </div>
            <div class="col-10" [ngStyle]="{ color: textColor }">
              <h1 style="font-family: 'Circe ExtraBold'" translate>In Russia,</h1>
              <h1 style="font-family: 'Circe Bold'" translate>salary taxes are: health insurance, pension contributions, social
                insurance fund.</h1>
              <h1 style="font-family: 'Circe Bold'" translate>For non-residents there are special personnel accounting rules.</h1>
            </div>
          </div>
        </div>
        <!-- end of "In Russia" col -->

        <!-- "Implementation of personnel records" col -->
        <div class="col-sm-11 col-12 blue-background p-4 pt-5 p-sm-5 m-0 ml-sm-3 my-sm-5 my-0">
          <h1 class="text-white" style="font-family: 'Circe Bold'" translate>Implementation of personnel records <br translate> and
            reporting to state bodies.</h1>

          <div class="row">
            <div class="col-sm-5 m-4">
              <h4 class="text-white my-5" style="font-family: 'Circe Bold'" translate>Companies <br translate> up to 10 people</h4>
              <h5 class="text-white" style="font-family: 'Circe'" translate>150 EUR per month.</h5>
            </div>

            <div class="col-sm-5 m-4">
              <h4 class="text-white my-5" style="font-family: 'Circe Bold'" translate>Companies <br translate> from 10 people</h4>
              <h5 class="text-white" style="font-family: 'Circe'" translate>Consider each time individually.</h5>
            </div>
          </div>

        </div>
        <!-- end of "Implementation of personnel records" col -->

      </div>
      <!-- end of Col for center alignment -->

    </div>

  </div>

  <app-footer></app-footer>

</section>
