import {Component, OnInit} from '@angular/core';
import {Time} from '../time';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends Time {

  footerBlackLogo = 'assets/img/logo-black.png';
  footerWhiteLogo = 'assets/img/logo-white.png';
}
