export class Time {
  dateTime = new Date();
  // standard is 21:00
  evening = 21;
  // standard is 6:00
  morning = 6;

  // section bg-color
  sectionBGColor;
  // bg-color for cols with text etc.
  blockBGColor;
  // bg-color for "blue cols"
  extraBGColor;
  // color for text
  textColor;

  dark = '#242425';
  gray = '#F5F5F5';
  white = '#FFFFFF';
  blue = '#0C23FE';
  grayText = '#9A9A9A';

  constructor() {
    if (this.dateTime.getHours() >= this.evening || this.dateTime.getHours() <= this.morning) {
      this.sectionBGColor = this.dark;
      this.blockBGColor = this.dark;
      this.extraBGColor = this.dark;
      this.textColor = this.grayText;
    } else {
      this.sectionBGColor = this.gray;
      this.blockBGColor = this.white;
      this.extraBGColor = this.blue;
      this.textColor = this.dark;
    }
  }
}
