import { Component } from '@angular/core';
import {Time} from '../time';

@Component({
  selector: 'app-company-formation',
  templateUrl: './company-formation.component.html',
  styleUrls: ['./company-formation.component.scss']
})
export class CompanyFormationComponent extends Time{
}
