<section [ngStyle]="{backgroundColor: sectionBGColor}">

  <app-navbar></app-navbar>

  <div class="container-fluid">

    <div class="row justify-content-center">

      <div class="col-sm-9">
        <!-- "Business Advice" heading col -->
        <div class="col-sm-12 py-5" [ngStyle]="{color: textColor}">
          <h1 style="font-family: 'Circe Bold'; font-size: xxx-large" translate>Business Advice and Legal Issues</h1>
        </div>
        <!-- end of "Business Advice" heading col -->

        <div class="col-sm-12">
          <h1 style="font-family: 'Circe Bold'; font-size: xx-large" [ngStyle]="{color: textColor}" translate>We will
            help to
            understand:</h1>
        </div>

        <!-- Col for list -->
        <div class="col-sm-12 row py-5">
          <div class="col-sm-5" [ngStyle]="{color: textColor}">
            <ul class="pl-0 ml-3" style="font-family: 'Circe Bold'">
              <li class="py-2"><h5 translate>Migration registration issues</h5></li>
              <li class="py-2"><h5 translate>In the form of business ownership</h5></li>
              <li class="py-2"><h5 translate>In order to check contractors, <br translate> suppliers and customers</h5>
              </li>
              <li class="py-2"><h5 translate>In choosing a tax system</h5></li>
            </ul>
          </div>
          <!------------->
          <div class="col-sm-5" [ngStyle]="{color: textColor}">
            <ul class="pl-0 ml-3" style="font-family: 'Circe Bold'">
              <li class="py-2"><h5 translate>In matters of personnel records</h5></li>
              <li class="py-2"><h5 translate>In the Software necessary for reporting</h5></li>
              <li class="py-2"><h5 translate>Choosing a bank, acquiring and payment systems</h5></li>
              <li class="py-2"><h5 translate>Other issues</h5></li>
            </ul>
          </div>
        </div>
        <!-- end of Col for list -->

        <div class="col-sm-10 px-0 pb-5 my-3" [ngStyle]="{backgroundColor: blockBGColor, color: textColor}">
          <div class="col-sm-12 pl-5 blue-background py-4">
            <h1 class="text-white" style="font-family: 'Circe Bold'; font-size: xxx-large" translate>Legal Issues</h1>
          </div>
          <div class="row my-5">
            <div class="col-1">
              <div class="red-stripe"></div>
            </div>
            <div class="col-10 d-flex">
              <p class="align-self-center" style="font-family: 'Circe Bold'; font-size: large" translate>We represent
                the
                interests of
                companies in state bodies of Russia and in the judicial system.</p>
            </div>
          </div>

          <div class="row mt-5 ">
            <div class="col-1">
              <div class="red-stripe"></div>
            </div>
            <div class="col-10 d-flex">
              <p style="font-family: 'Circe Bold'; font-size: large" translate>We also conduct negotiations with
                Russian financial institutions, as well as with foreign financial institutions that operate in the
                Russian Federation.</p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <app-footer></app-footer>

</section>
