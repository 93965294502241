<section
  [ngStyle]="{ backgroundColor: sectionBGColor }">

  <app-navbar></app-navbar>

  <div class="row no-gutters">

    <!-- col for alignment with logo col and content col -->
    <div class="col-sm-10 landing-img d-flex flex-row">

      <!-- left col for logo -->
      <div
        class="col-sm-7 col-md-6 col-lg-7 col-xl-7 flex-column justify-content-between align-items-center align-self-center d-none d-md-block">

        <!-- logo img -->
        <div class="col-sm-12 d-flex justify-content-center">
          <img src="assets/img/logo-landing.png" class="img-fluid" style="width: 25rem;">
        </div>
        <!-- end of logo img -->

      </div>
      <!-- end of left col for logo -->

      <!-- right col for content -->
      <div
        class="col-sm-12 col-md-6 col-lg-5 col-xl-5 m-3 mx-sm-3 mb-md-0 mb-lg-0 mb-xl-0 align-self-sm-end no-gutters p-0">

        <!-- Who we are -->
        <div class="col-sm-12 pt-5 pb-3"
             [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">

          <div class="row">
            <!-- Stripe Col -->
            <div class="col-1">
              <div class="blue-stripe-h5"></div>
            </div>
            <!-- end of Stripe Col -->
            <div class="col-10 ml-2">
              <h4 class="text-left mt-1" style="font-family: 'Circe Bold'" translate>Who we are</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-9 ml-2">
              <p class="text-left" style="font-family: 'Circe Bold'" translate>Our headquarters is located in Russia,
                St.
                Petersburg. Our connections are worldwide.
                We are interested
                in your business being reliable, stable and profitable.</p>
            </div>
          </div>


        </div>
        <!-- end of Who we are -->

        <!-- Our mission -->
        <div class="col-sm-12 pt-3 pb-5"
             [ngStyle]="{ backgroundColor: blockBGColor, color: textColor}">


          <div class="row">
            <!-- Stripe Col -->
            <div class="col-1">
              <div class="red-stripe-h5"></div>
            </div>
            <!-- end of Stripe Col -->

            <div class="col-10">
              <h4 class="text-left mt-1" style="font-family: 'Circe Bold'" translate>Our mission</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-9">
              <p class="text-left" style="font-family: 'Circe Bold'" translate>Creating a healthy business environment.
                Search for
                optimal solutions. Harmonious,
                cultural and modern approach to business. Maximize the use of modern business and legal
                achievements.</p>
            </div>
          </div>

        </div>
        <!-- end of Our mission -->

        <div class="col-sm-6 d-flex flex-row py-4 ml-auto"
             [ngStyle]="{ backgroundColor: extraBGColor }">
          <div class="col-sm-4 p-2 border-right d-flex justify-content-center">
            <a href="https://web.wechat.com/?lang=en_US/Alexrus297"><img src="assets/img/wechat.png"
                                                                                  class="img-fluid"
                                                                                  style="width: 1.5rem"></a>
          </div>
          <div class="col-sm-4 p-2 border-right d-flex justify-content-center">
            <a href="https://wa.me/79312681293"><img src="assets/img/whatsapp.png" class="img-fluid"
                                                     style="width: 1.5rem"></a>
          </div>
          <div class="col-sm-4 p-2 d-flex justify-content-center">
            <a href="https://t.me/International_deal_spb"><img src="assets/img/telegram.png" class="img-fluid"
                                                                style="width: 1.5rem"></a>
          </div>
        </div>

      </div>
      <!-- end of right col for content -->

    </div>
    <!-- end of col for alignment with logo col and content col -->

    <!-- col for blue background at right (must be hidden on sm) -->
    <div class="col-sm-2 blue-background d-none d-sm-block" style="min-height: 78vh"
         [ngStyle]="{ backgroundColor: extraBGColor }"></div>
    <!-- end of col for blue background at right (must be hidden on sm) -->
  </div>

  <app-footer></app-footer>

</section>

