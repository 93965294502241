import {Component, OnInit} from '@angular/core';
import {Time} from '../time';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent extends Time {

}
