import {Component} from '@angular/core';
import {Time} from '../time';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends Time implements Time {
}








