<section [ngStyle]="{backgroundColor: sectionBGColor}" class="d-flex flex-column justify-content-between">

  <div>
    <app-navbar></app-navbar>

    <div class="container-fluid">

      <div class="row justify-content-center">

        <div class="col-sm-9 d-flex flex-column flex-lg-row">
          <!-- "Contacts" heading col -->
          <div class="col-sm-3 py-5" [ngStyle]="{color: textColor}">
            <h1 style="font-family: 'Circe Bold'; font-size: xxx-large" translate>Contacts</h1>
          </div>
          <!-- end of "Contacts" heading col -->

          <div class="col-sm-9 py-5 d-flex flex-column" [ngStyle]="{color: textColor}">
            <h5 style="font-family: 'Circe Bold';" translate>For any questions</h5>
            <div class="col-sm-12 py-5 pl-0 ml-0 d-flex flex-row">
              <div class="col-sm-5 pl-0 ml-0">
                <p style="font-family: 'Circe Bold'" [ngStyle]="{color: textColor}">E-mail</p>
                <p class="my-0" style="font-family: 'Circe Bold'; color: #0C23FE;">
                  contact@startbusiness-russia.com</p>
              </div>
              <div class="col-sm-7">
                <p style="font-family: 'Circe Bold'" [ngStyle]="{color: textColor}">Chats</p>
                <div class="col-sm-12 d-flex flex-column flex-lg-row justify-content-between ml-0 pl-0">
                  <a href="https://web.wechat.com/?lang=en_US/Alexrus297">
                    <div class="text-center d-flex">
                      <img class="img-fluid m-2" style="width: 1.5rem" src="assets/img/wechat.png">
                      <p class="m-2" style="font-family: 'Circe Bold'; color: #0C23FE;">
                        WeChat</p>
                    </div>
                  </a>
                  <a href="https://wa.me/79312681293">
                    <div class="text-center d-flex">
                      <img class="img-fluid m-2" style="width: 1.5rem" src="assets/img/whatsapp.png">
                      <p class="m-2" style="font-family: 'Circe Bold'; color: #0C23FE;">
                        WhatsApp</p>
                    </div>
                  </a>
                  <a href="https://t.me/International_deal_spb">
                    <div class="text-center d-flex">
                      <img class="img-fluid m-2" style="width: 1.5rem" src="assets/img/telegram.png">
                      <p class="m-2" style="font-family: 'Circe Bold'; color: #0C23FE;">
                        Telegram</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

  <app-footer></app-footer>

</section>

