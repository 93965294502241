<nav class="navbar navbar-expand-lg sticky-top row m-0 p-0"
     [ngStyle]="{ backgroundColor: sectionBGColor}">
  <div class="col-sm-4 d-flex d-sm-block justify-content-between align-items-center text-center">
    <a class="navbar-brand " routerLink="/"><img
      [src]="dateTime.getHours() >= evening || dateTime.getHours() <= morning ? navbarWhiteLogo : navbarBlackLogo"
      class="img-fluid"
      style="max-width: 9rem"></a>
    <button class="navbar-toggler text-white" style="background-color: #0C23FE" type="button" data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span><svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" class="bi bi-grid" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd"
        d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
</svg></span>
    </button>
  </div>

  <span class="blue-stripe-vertical d-none d-lg-block"></span>
  <span class="red-stripe-vertical d-none d-lg-block"></span>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav align-items-center col-sm-12 p-0"
         [ngStyle]="{ backgroundColor: extraBGColor }"
         style="font-family: 'Circe'">
      <div
        class="col-sm-9 ml-sm-3 ml-0 text-center justify-content-between d-flex flex-sm-row flex-column"
        [ngStyle]="{ backgroundColor: extraBGColor }" style="min-height: fit-content">
        <a class="nav-item nav-link m-2 m-sm-0" routerLink="/company-formation" routerLinkActive="active" translate>Company
          Formation</a>
        <a class="nav-item nav-link m-2 m-sm-0" routerLink="/accounting" routerLinkActive="active" translate>Accounting</a>
        <a class="nav-item nav-link m-2 m-sm-0" routerLink="/payroll" routerLinkActive="active" translate>Payroll</a>
        <a class="nav-item nav-link m-2 m-sm-0" routerLink="/business-advice" routerLinkActive="active" translate>Business
          Advice</a>
        <a class="nav-item nav-link m-2 m-sm-0" routerLink="/contact" routerLinkActive="active" translate>Contacts</a>
      </div>

    </div>
  </div>
</nav>
